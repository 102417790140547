<script lang="ts" setup>
import { useUserStore } from "~/store/user";
import { type Ref } from "vue";
import ButtonSecondary from "~/components/ButtonSecondary.vue";
import { useCartPrices } from "~/composables/cart";
import ModalBox from "~/components/ModalBox.vue";
import H3Heading from "~/components/H3Heading.vue";
import ButtonPrimary from "~/components/ButtonPrimary.vue";
import { jsPDF } from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { STATES } from "~/constants/states";
import SelectTag from "~/components/form/SelectTag.vue";
import InputTag from "~/components/form/InputTag.vue";
import LabelTag from "~/components/form/LabelTag.vue";
import axios from "axios";
import { useHelper } from "~/composables/helper";
import { useCartStore } from "~/store/cart";
import { navigateTo } from "#app";
import InputAddressTag from "~/components/form/InputAddressTag.vue";
import { debounce } from "perfect-debounce";
import { useCheckoutStore } from "~/store/checkout";

// Data
const config = useRuntimeConfig();

const { moneyFormatter, subtotal, salesTax, total, qty, shippingCost } = useCartPrices();
const cartItems = computed(() => useCartStore().items);
await useUserStore().fetchUser(false);
const user = useUserStore().user;
const isLoggedIn = ref(false);
const showModalBox: Ref<boolean> = ref(false);
const quoteDownloaded = ref(false);
const processing = ref(false);

const orderNumber = ref("");
type OrderLineItem = { product_id: number; isbn: string; name: string; qty: number; each: number; total: number };
const lineItems = ref([] as OrderLineItem[]);

const form = ref({
    user_id: "",
    name: "",
    org: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    phone: "",
    email: "",
    products: cartItems.value,
});
const errorMsg = ref("");
const { getApiError } = useHelper();

// Functions
function handleShowModalBox() {
    // Set trigger to download quote after login
    if (!isLoggedIn.value) {
        localStorage.setItem("download-quote", "1");
        navigateTo("/login");
    } else {
        showModalBox.value = true;
    }
}

function downloadQuote() {
    let line = 1;
    const pageWidth = 8.5,
        lineHeight = 1.2,
        margin = 0.5,
        fontSize = 12,
        ptsPerInch = 72,
        oneLineHeight = (fontSize * lineHeight) / ptsPerInch,
        doc = new jsPDF({
            unit: "in",
            lineHeight: lineHeight,
        }).setProperties({ title: "ABC Quote" });

    // Date
    let posY = margin + 0.15 + line++ * oneLineHeight;
    doc.setFont("Helvetica", "bold")
        .setFontSize(fontSize)
        .text(`Quote Date:`, pageWidth - 4.0, posY);
    doc.setFont("Helvetica", "normal")
        .setFontSize(fontSize)
        .text(`${new Date().toLocaleDateString()}`, pageWidth - 4.0 + 1.0, posY);
    // Customer info
    doc.setFont("Helvetica", "bold")
        .setFontSize(fontSize)
        .text(`Quote #:`, margin + 0.35, posY);
    doc.setFont("Helvetica", "normal")
        .setFontSize(fontSize)
        .text(`${orderNumber.value}`, margin + 1.15, posY);
    posY = margin + 0.15 + line++ * oneLineHeight;
    doc.setFont("Helvetica", "bold")
        .setFontSize(fontSize)
        .text("Customer:", margin + 0.2, posY);
    doc.setFont("Helvetica", "normal")
        .setFontSize(fontSize)
        .text(form.value.name, margin + 1.15, posY);
    doc.setFont("Helvetica", "bold")
        .setFontSize(fontSize)
        .text(`BuyBoard #:`, pageWidth - 4.0, posY);
    doc.setFont("Helvetica", "normal")
        .setFontSize(fontSize)
        .text(`748-24`, pageWidth - 4.0 + 1.0, posY);
    posY = margin + 0.15 + line++ * oneLineHeight;
    doc.setFont("Helvetica", "bold").setFontSize(fontSize).text("Customer ID:", margin, posY);
    doc.setFont("Helvetica", "normal")
        .setFontSize(fontSize)
        .text(user.id.toString(), margin + 1.15, posY);
    posY = margin + 0.15 + line++ * oneLineHeight;
    doc.setFont("Helvetica", "bold").setFontSize(fontSize).text("Organization:", margin, posY);
    doc.setFont("Helvetica", "normal")
        .setFontSize(fontSize)
        .text(form.value.org, margin + 1.15, posY);

    // ABC logo
    doc.addImage("/images/abc-com-plain-colour.png", "PNG", pageWidth - 1.6, 0.2, 1, 1);

    // Ship To
    line = 7;
    posY = margin + line++ * oneLineHeight;
    doc.setLineWidth(pageWidth / 2)
        .setFont("Helvetica", "bold")
        .text("Shipping Address:", margin, posY);
    posY = margin + line++ * oneLineHeight;
    doc.setLineWidth(pageWidth / 2)
        .setFont("Helvetica", "normal")
        .text(form.value.street, margin, posY);
    doc.setFont("Helvetica", "normal")
        .setFontSize(fontSize)
        .text(`${form.value.phone}`, pageWidth - 4.0, posY);
    posY = margin + line++ * oneLineHeight;
    doc.setLineWidth(pageWidth / 2).text(`${form.value.city}, ${form.value.state} ${form.value.zip}`, margin, posY);
    doc.setFont("Helvetica", "normal")
        .setFontSize(fontSize)
        .text(`${form.value.email}`, pageWidth - 4.0, posY);

    // Table products
    const rows: RowInput[] = [];
    lineItems.value.forEach((item) => {
        rows.push([
            item.isbn,
            item.name,
            item.qty,
            moneyFormatter.format(item.each / 100), // Convert cents to dollars
            moneyFormatter.format(item.total / 100), // Convert cents to dollars
        ]);
    });
    let tableHeight = 0;
    let tableMeta = null;
    autoTable(doc, {
        theme: "striped", // 'striped'|'grid'|'plain
        margin: { top: 3 },
        head: [["ISBN", "Name", "Qty", "Each", "Price"]],
        body: rows,
        didParseCell: function (data) {
            if (data.table) {
                tableMeta = data.table;
            }
        },
    });

    tableHeight = tableMeta?.finalY; // FinalY is defined after table has been render.
    line = -1; // Reset line to start after table
    const leftSideOffset = 1.5;
    const rightSideOffset = 2.9;
    // Subtotal
    posY = margin + line++ * oneLineHeight + tableHeight;
    doc.setFont("Helvetica", "bold")
        .setLineWidth(pageWidth / 3)
        .text("Subtotal", margin + pageWidth / 2 + leftSideOffset, posY, { align: "right" });
    doc.setFont("Helvetica", "normal")
        .setLineWidth(pageWidth / 3)
        .text(moneyFormatter.format(subtotal.value), margin + pageWidth / 2 + rightSideOffset, posY, {
            align: "right",
        });
    // Shipping
    posY = margin + line++ * oneLineHeight + tableHeight;
    doc.setFont("Helvetica", "bold")
        .setLineWidth(pageWidth / 3)
        .text("Shipping", margin + pageWidth / 2 + leftSideOffset, posY, { align: "right" });
    doc.setFont("Helvetica", "normal")
        .setLineWidth(pageWidth / 3)
        .text(moneyFormatter.format(shippingCost.value), margin + pageWidth / 2 + rightSideOffset, posY, {
            align: "right",
        });
    // Sales tax
    posY = margin + line++ * oneLineHeight + tableHeight;
    doc.setFont("Helvetica", "bold")
        .setLineWidth(pageWidth / 3)
        .text("Sales Tax", margin + pageWidth / 2 + leftSideOffset, posY, { align: "right" });
    doc.setFont("Helvetica", "normal")
        .setLineWidth(pageWidth / 3)
        .text(moneyFormatter.format(salesTax.value), margin + pageWidth / 2 + rightSideOffset, posY, {
            align: "right",
        });
    // Total
    line++;
    line++;
    posY = margin + line++ * oneLineHeight + tableHeight;
    doc.setFont("Helvetica", "bold")
        .setLineWidth(pageWidth / 3)
        .setFontSize(16)
        .text("Total", margin + pageWidth / 2 + leftSideOffset, posY, { align: "right" });
    doc.setFont("Helvetica", "normal")
        .setLineWidth(pageWidth / 3)
        .setFontSize(16)
        .text(moneyFormatter.format(total.value), margin + pageWidth / 2 + rightSideOffset, posY, { align: "right" });

    // Footer
    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
        doc.setPage(i);
        const text = "103 Executive Drive \u2022 Woodstock, GA 30188 \u2022 (p) 888-264-5877 \u2022 (f) 866-827-3240";
        doc.text(text, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 0.1, {
            align: "center",
        });
    }

    doc.save("ABC-Quote.pdf");
    quoteDownloaded.value = true;
}

async function processDownload() {
    try {
        errorMsg.value = "";
        processing.value = true;
        form.value.products = cartItems.value; // Update to the most recent cart items

        // Submit form
        const response = await axios.post(config.public.STATAMIC_API + "/store/submit-quote", form.value);

        orderNumber.value = response.data.order_number;
        lineItems.value = response.data.line_items;

        downloadQuote();
    } catch (e) {
        errorMsg.value = getApiError(e);
        console.log(e);

        if (e.response?.status === 401) {
            // Expired session. Remove user and redirect to login page.
            localStorage.setItem("download-quote", "1");
            useUserStore().removeUser(false);

            let secondCountDown = 5;
            errorMsg.value = `<div class="text-abc-red">Login session has expired.<br/>You will be redirected to the login page in ${secondCountDown} seconds...</div>`;
            const intervalId = setInterval(() => {
                secondCountDown--;
                errorMsg.value = `<div class="text-abc-red">Login session has expired.<br/>You will be redirected to the login page in ${secondCountDown} seconds...</div>`;
                if (secondCountDown === 0) {
                    clearInterval(intervalId);
                    navigateTo("/login");
                }
            }, 1000);
        }
    }
    processing.value = false;
}

const pageMounted = ref(false);

const checkoutShippingAddress = useCheckoutStore().shippingAddress;

onMounted(async () => {
    // Always pull from user shipping address if not provided.
    form.value.name = checkoutShippingAddress.name
        ? checkoutShippingAddress.name
        : user?.first_name + " " + user?.last_name;
    form.value.org = checkoutShippingAddress.orgName ? checkoutShippingAddress.orgName : user?.organization?.name;
    form.value.street = checkoutShippingAddress.street
        ? checkoutShippingAddress.street
        : user?.shipping_address?.street;
    form.value.city = checkoutShippingAddress.city ? checkoutShippingAddress.city : user?.shipping_address?.city;
    form.value.state = checkoutShippingAddress.state ? checkoutShippingAddress.state : user?.shipping_address?.state;
    form.value.zip = checkoutShippingAddress.zip ? checkoutShippingAddress.zip : user?.shipping_address?.zip;

    form.value.user_id = user?.id;
    form.value.phone = user?.shipping_address?.phone;
    form.value.email = user?.email;

    isLoggedIn.value = useUserStore().isLoggedIn;
    if (isLoggedIn.value && localStorage.getItem("download-quote")) {
        localStorage.removeItem("download-quote");
        handleShowModalBox();
    }
    pageMounted.value = true;
});

const debounceSetFetchCartAndSummary = debounce(async () => {
    // Also, update checkout store shipping address
    checkoutShippingAddress.state = form.value.state;
    checkoutShippingAddress.zip = form.value.zip;
    processing.value = true;
    await useCartStore().fetchCartAndSummary(true, form.value.state, form.value.zip);
    processing.value = false;
}, 1000);
watch(
    () => form.value.state,
    async (newVal, oldVal) => {
        if (pageMounted.value) {
            await debounceSetFetchCartAndSummary();
        }
    },
);
watch(
    () => form.value.zip,
    async (newVal, oldVal) => {
        if (pageMounted.value) {
            await debounceSetFetchCartAndSummary();
        }
    },
);
</script>

<template>
    <div>
        <ButtonSecondary v-if="qty > 0" class="mt-10 w-full" @click="handleShowModalBox">
            Download a Quote
        </ButtonSecondary>

        <ModalBox v-model:showModalBox="showModalBox" width="w-11/12 md:w-2/5">
            <div>
                <div v-if="quoteDownloaded">
                    <H3Heading size="text-xl" class="text-center">Your quote has been downloaded</H3Heading>
                    <p class="text-center">
                        If you have any questions, please contact our Customer Service team at
                        <NuxtLink to="tel:18882645877" class="whitespace-nowrap text-abc-blue">
                            1-888-264-5877
                        </NuxtLink>
                        .
                    </p>
                    <div class="text-center">
                        <ButtonPrimary
                            class="mt-8 w-full md:w-1/2"
                            @click="
                                showModalBox = false;
                                quoteDownloaded = false;
                            "
                        >
                            Ok
                        </ButtonPrimary>
                    </div>
                </div>
                <div v-else>
                    <H3Heading size="text-xl" class="mb-2 text-center">Download a Quote</H3Heading>

                    <H4Heading size="text-lg mb-2">Shipping Address:</H4Heading>
                    <form @submit.prevent="processDownload">
                        <div>
                            <LabelTag for="s_name">Contact Name *</LabelTag>
                            <InputTag id="s_name" v-model="form.name" class="w-full" required />
                        </div>
                        <div class="mt-4">
                            <LabelTag for="s_org">Organization *</LabelTag>
                            <InputTag id="s_org" v-model="form.org" autocomplete="on" class="w-full rounded" required />
                        </div>
                        <div class="mt-4">
                            <LabelTag for="s_street">Address *</LabelTag>
                            <InputAddressTag
                                v-model:street="form.street"
                                v-model:city="form.city"
                                v-model:state="form.state"
                                v-model:zip="form.zip"
                                class="w-full rounded"
                                placeholder="Enter your address"
                                required
                            />
                        </div>
                        <div class="mt-4 md:flex">
                            <div class="flex-1 md:pr-4">
                                <LabelTag for="s_city">City *</LabelTag>
                                <InputTag
                                    id="s_city"
                                    v-model="form.city"
                                    autocomplete="on"
                                    class="w-full rounded"
                                    required
                                />
                            </div>
                            <div class="mt-4 flex-none md:mt-0 md:w-1/2">
                                <LabelTag for="s_state">State *</LabelTag>
                                <SelectTag
                                    id="s_state"
                                    v-model="form.state"
                                    autocomplete="on"
                                    class="w-full rounded"
                                    required
                                >
                                    <option v-for="(val, key) in STATES" :key="'s_state' + key" :value="key">
                                        {{ val }}
                                    </option>
                                </SelectTag>
                            </div>
                        </div>
                        <div class="mt-4 md:flex">
                            <div class="mt-4 flex-none md:mt-0 md:w-1/2 md:pr-4">
                                <LabelTag for="s_zip">ZIP Code *</LabelTag>
                                <InputTag
                                    id="s_zip"
                                    v-model="form.zip"
                                    autocomplete="on"
                                    class="w-full rounded"
                                    required
                                />
                            </div>
                        </div>
                        <div class="mt-4 md:flex">
                            <div class="mt-4 flex-none md:mt-0 md:w-1/2 md:pr-4">
                                <LabelTag for="s_phone">Phone *</LabelTag>
                                <InputTag
                                    id="s_phone"
                                    v-model="form.phone"
                                    autocomplete="on"
                                    class="w-full rounded"
                                    required
                                />
                            </div>
                            <div class="flex-1">
                                <LabelTag for="s_email">Email *</LabelTag>
                                <InputTag
                                    id="s_email"
                                    v-model="form.email"
                                    autocomplete="on"
                                    class="w-full rounded"
                                    required
                                />
                            </div>
                        </div>

                        <div v-if="errorMsg" class="mt-6 text-center" v-html="errorMsg"></div>

                        <div class="mt-10 text-center">
                            <ButtonPrimary type="submit" class="w-full md:w-2/5" :disabled="processing">
                                Download
                            </ButtonPrimary>
                            <ButtonSecondary class="mt-2 w-full md:ml-2 md:mt-0 md:w-2/5" @click="showModalBox = false">
                                Cancel
                            </ButtonSecondary>
                        </div>
                    </form>
                </div>
            </div>
        </ModalBox>
    </div>
</template>

<style scoped></style>

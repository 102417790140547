<script lang="ts" setup>
import { ref } from "vue";
import CartItemDetails from "~/components/cart/CartItemDetails.vue";
import WishlistBooksNotInCart from "~/components/cart/WishlistBooksNotInCart.vue";
import { useCartStore } from "~/store/cart";

const cartItems = computed(() => useCartStore().items);
const hasLoadedCartItems = ref(false);

// get the cart and update our cartItems when we have a response
await useCartStore().fetchCartAndSummary();
if (process.client) {
    hasLoadedCartItems.value = true;
}
</script>

<template>
    <div>
        <H1Heading size="text-3xl lg:text-4xl" class="mb-6">Your Cart</H1Heading>

        <div v-if="hasLoadedCartItems" class="mb-10 flex flex-col flex-col-reverse justify-between lg:flex-row">
            <div v-if="!cartItems || cartItems.length === 0" class="flex-1 text-center text-gray-600">
                No items in cart
            </div>
            <div v-if="cartItems && cartItems.length !== 0" class="flex-1 overflow-hidden lg:mr-8">
                <CartItemDetails
                    v-for="item in cartItems"
                    :key="item.product_id"
                    :item="item"
                    class="mt-2 border-b py-6 last:border-b-0"
                />
            </div>
            <div v-if="cartItems && cartItems.length !== 0" class="mb-8 flex-none lg:mb-0 lg:w-2/6 xl:w-1/4">
                <CartOrderSummary class="bg-abc-gray p-4" />
            </div>
        </div>

        <img
            v-if="hasLoadedCartItems && (!cartItems || cartItems.length === 0)"
            src="/images/need-to-download-a-quote-wide.svg"
            class="mb-4 mt-20"
            alt="ABC Logo"
        />

        <WishlistBooksNotInCart class="mb-12" />
    </div>
</template>
